
import { Vue, Component } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { FetchSuggestionsCallback } from 'element-ui/types/autocomplete'
import { PolygonPathDetail, PolygonStyle } from '@/types/common'
import { ProjectBaseDetail } from '@/types/project'
import LocationImg from '@/assets/img/icon_address.png'
import { drawPolygon } from '@/utils/formatData'

@Component
export default class AreaSet extends Vue {
  private isDraw = false
  private isAct = false
  private projectInfo: ProjectBaseDetail = {
    projectId: '',
    projectName: '',
    projectProvince: '',
    projectCity: '',
    projectArea: '',
    projectAddr: '',
    longitude: '',
    latitude: ''
  }

  private projectLocation: Array<PolygonPathDetail> = []
  private areaLocation: Array<{ projectAreaId: string; list: Array<PolygonPathDetail> }> = []
  private map: any = null
  private mouseTool: any = null
  private overlays: Array<any> = []
  private selectPolygon: { ind: number; polygon: AMap.Polygon | null } = { ind: -1, polygon: null }
  private polygonSelectStyle: PolygonStyle = {
    strokeColor: '#FF402B',
    fillColor: '#FF402B',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.5,
    strokeStyle: 'solid'
  }

  private polygonCurrentStyle: PolygonStyle = {
    strokeColor: '#9D4DFF',
    fillColor: '#9D4DFF',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.5,
    strokeStyle: 'solid',
    cursor: 'pointer',
    zIndex: 99
  }

  private polygonStyle: PolygonStyle = {
    strokeColor: '#3592FE',
    fillColor: '#3592FE',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.3,
    strokeStyle: 'solid',
    zIndex: 0
  }

  private marker: Array<any> = []
  private currentIndex = 0

  created () {
    this.getProjectInfo()
  }

  // 获取项目信息
  getProjectInfo () {
    this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
      projectId: this.$route.query.projectId
    }).then((res) => {
      if (res) {
        this.projectInfo = res
        this.projectLocation = this.projectInfo.locationList || []
        const lng = Number(this.projectInfo.longitude)
        const lat = Number(this.projectInfo.latitude)
        this.$nextTick(() => {
          this.initMap(lng, lat)
        })
      } else {
        this.$message.error('未获取到项目信息')
      }
    })
  }

  // 详细地址输入
  addrFetch (queryString: string, cb: FetchSuggestionsCallback) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: [
        'AMap.AutoComplete'
      ]
    }).then((AMap) => {
      // 实例化Autocomplete
      const autoComplete = new AMap.Autocomplete({
        city: this.projectInfo.projectArea
      })
      autoComplete.search(this.projectInfo.projectArea + queryString, (status: string, result: AMap.Autocomplete.SearchResult) => {
        if (status === 'complete') {
          cb(result.tips)
        }
      })
    }).catch(e => {
      console.log(e)
    })
  }

  // 点击详细地址下拉
  addrChange (data: AMap.Autocomplete.Tip) {
    this.projectInfo.projectAddr = data.name
    this.projectInfo.latitude = data.location.getLat().toString()
    this.projectInfo.longitude = data.location.getLng().toString()
    this.map.setCenter([Number(this.projectInfo.longitude), Number(this.projectInfo.latitude)])
  }

  // 初始化地图
  initMap (lng: number, lat: number) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: [
        'AMap.MouseTool'
      ]
    }).then((AMap) => {
      this.map = new AMap.Map('rangeMapContainer', {
        center: [lng, lat],
        zoom: 15
      })
      this.addMarker(lng, lat)
      // 画项目电子围栏
      this.overlays = drawPolygon(this.map, this.projectLocation, this.polygonCurrentStyle, this.polygonClick)
      this.mouseTool = new AMap.MouseTool(this.map)
      this.mouseTool.on('draw', (e: any) => {
        // 面积计算，清除掉面积为0的非围栏所绘线条，四舍五入，面积小于0.5平方米的也舍弃
        const area = Math.round(AMap.GeometryUtil.ringArea(e.obj.getPath()))
        if (area > 0) {
          this.overlays.push(e.obj)
        } else {
          this.map.remove(e.obj)
          this.$message.warning('当前所画图形面积为0，无法保存。')
        }
      })
    })
  }

  addMarker (lng: number, lat: number) {
    this.map.remove(this.marker)
    this.marker = []
    const marker: any = new AMap.Marker({
      map: this.map,
      position: new AMap.LngLat(lng, lat),
      offset: new AMap.Pixel(-20, -10),
      icon: LocationImg
    })
    this.marker.push(marker)
    this.map.add(marker)
    this.map.setCenter([lng, lat])
  }

  polygonClick (e: any) {
    const ind = this.overlays.findIndex(item => item.contains(e.lnglat))
    if (ind >= 0) {
      if (!this.isAct) {
        this.selectPolygon.polygon && this.selectPolygon.polygon.setOptions(this.polygonCurrentStyle)
        this.selectPolygon.ind = ind
        this.selectPolygon.polygon = this.overlays[ind] as AMap.Polygon
        this.selectPolygon.polygon.setOptions(this.polygonSelectStyle)
      } else {
        this.lnglatClick(e)
      }
    }
  }

  // 地图操作鼠标项
  closed () {
    this.isDraw = false
    this.isAct = false
    this.mouseTool.close()
    this.overlays.forEach(item => {
      item.on('click', this.polygonClick)
    })
  }

  // 地图操作电子围栏
  openDraw () {
    this.overlays.forEach(item => {
      item.off('click', this.polygonClick)
    })
    this.isDraw = true
    this.isAct = false
    this.mouseTool.polygon(this.polygonCurrentStyle)
  }

  // 地图操作中心点
  openisAct () {
    this.isDraw = false
    this.isAct = true
    this.mouseTool.close()
    this.selectPolygon.polygon && this.selectPolygon.polygon.setOptions(this.polygonCurrentStyle)
    this.overlays.forEach(item => {
      item.on('click', this.polygonClick)
    })
    this.map.on('click', this.lnglatClick)
  }

  // 鼠标点击中心点
  lnglatClick (e: any) {
    if (this.isAct && e.lnglat.lng && e.lnglat.lat) {
      this.addMarker(e.lnglat.lng, e.lnglat.lat)
      this.$confirm('是否更新当前项目中心点', '更新', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.submitCenter(e.lnglat.lng, e.lnglat.lat)
      }).catch(() => {
        this.addMarker(Number(this.projectInfo.longitude), Number(this.projectInfo.latitude))
      })
    }
  }

  // 保存中心点
  submitCenter (lng: number, lat: number) {
    this.$axios.post(this.$apis.project.updateYhProjectMap, {
      projectId: this.$route.query.projectId,
      longitude: lng,
      latitude: lat
    }).then(() => {
      this.$message.success('更新中心点成功！')
    })
  }

  // 保存围栏
  saveRange () {
    if (this.overlays.length === 0) {
      this.$message('请至少画出一个项目围栏再做保存')
    } else {
      const location: Array<{latitude: Array<number>; longitude: Array<number>}> = []
      this.overlays.forEach((item: any) => {
        const lng: number[] = []
        const lat: number[] = []
        const path: Array<{ lng: number; lat: number }> = item.getPath()
        path.forEach((cItem: { lng: number; lat: number }) => {
          lng.push(cItem.lng)
          lat.push(cItem.lat)
        })
        location.push({
          latitude: lat,
          longitude: lng
        })
      })
      this.$axios.post(this.$apis.project.insertAllYhLocation, {
        objectId: this.$route.query.projectId,
        locations: location
      }).then(() => {
        this.$message.success('添加围栏成功')
      })
    }
  }

  // 清除选中的围栏
  clearSelectPolygon () {
    if (this.selectPolygon.ind >= 0) {
      this.$confirm('确定要删除选中的围栏？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.removePolygon()
      })
    }
  }

  // 从数组中清除选中的电子围栏
  removePolygon () {
    this.$message.success('删除围栏成功')
    this.map && this.map.remove(this.selectPolygon.polygon)
    this.overlays.splice(this.selectPolygon.ind, 1)
    this.selectPolygon = { ind: -1, polygon: null }
  }

  // 清除所有围栏
  clearRange () {
    this.$confirm('确定要删除所有围栏？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$message.success('删除围栏成功')
      this.map && this.map.remove(this.overlays)
      this.overlays = []
    })
  }

  changeLayer (value: number) {
    this.currentIndex = value
    this.map.setLayers([])
    if (value === 0) {
      // 添加标准地图图层
      this.map.setMapStyle('amap://styles/normal')
      this.map.add(new AMap.TileLayer())
    } else {
      // 添加卫星地图图层
      this.map.add(new AMap.TileLayer.Satellite())
    }
  }
}
